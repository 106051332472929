import { template as template_ac2dac5cb3d54df0874d5daacfdc1e0a } from "@ember/template-compiler";
const SidebarSectionMessage = template_ac2dac5cb3d54df0874d5daacfdc1e0a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
